import React, { useState } from "react";
import axios from "axios";
import { Box, CssBaseline, Typography, TextField, Button, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CodeBlock from "./CodeBlock";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#90caf9" },
    background: { default: "#121212", paper: "#1e1e1e" },
    text: { primary: "#ffffff" },
  },
  typography: {
    fontFamily: "Roboto, monospace",
    h4: { fontWeight: 700, fontSize: "2rem" },
    body1: { fontSize: "1.1rem" },
  },
});

function App() {
  const [website, setWebsite] = useState("");
  const [language, setLanguage] = useState("");
  const [apiExample, setApiExample] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGenerate = async () => {
    if (!website || !language) return;
    setLoading(true);
    try {
      const response = await axios.post("https://apiexamplesearch-backend-912c8228b71d.herokuapp.com/api/generate", {
        website,
        language,
      });
      setApiExample(response.data.code);
    } catch (error) {
      console.error("Error generating API example:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
        }}
      >
        <Container maxWidth="lg" sx={{ textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            API Example Search
          </Typography>
          <Typography variant="body1" gutterBottom>
            Generate API examples for your favorite websites or apps and programming languages.
          </Typography>
          <Box
            component="form"
            sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}
            onSubmit={(e) => e.preventDefault()}
          >
            <TextField
              label="Website URL or App Name"
              variant="outlined"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Programming Language"
              variant="outlined"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              fullWidth
              required
            />
            <Button variant="contained" color="primary" onClick={handleGenerate}>
              {loading ? "Generating..." : "Generate API Example"}

            </Button>
          </Box>
            <Box mt={4}>
            {apiExample && (
              <>
                <Typography variant="h6">Generated API Example:</Typography>
                <CodeBlock code={apiExample} language={language} />
              </>
            )}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
