import React from "react";
import { Box } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { duotoneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlock = ({ code, language }) => {
    return (
      <Box
        sx={{
          mt: 3,
          p: 2,
          borderRadius: "5px",
          backgroundColor: "#1e1e1e",
          overflowX: "auto",
        }}
      >
        <SyntaxHighlighter
          language={language}
          style={dracula}
          showLineNumbers
          wrapLines={true}
        >
          {code}
        </SyntaxHighlighter>
      </Box>
    );
  };

export default CodeBlock;
